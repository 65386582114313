import * as React from "react"
import PageLayout from "../../../components/page-layout"
import ExternalLink from "../../../components/external-link"
import Navbar from "../../../components/navbar"
import { Link } from "gatsby"

import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"

import Suicune1 from "./img/1.jpg"
import Suicune2 from "./img/2.jpg"
import Suicune3 from "./img/3.jpg"
import Suicune4 from "./img/4.jpg"
import Suicune5 from "./img/5.jpg"
import Suicune6 from "./img/6.jpg"
import Suicune7 from "./img/7.jpg"
import Suicune8 from "./img/8.jpg"
import Suicune9 from "./img/9.jpg"
import CalculationsPdf from "./calculations.pdf"

import "../style.css"
import FadeIn from "react-fade-in/lib/FadeIn"

const SuicunePage = () => {
  return (
    <PageLayout title="suicune | grac">
      <body id="suicune-page" className="page">
        <div className="center-container">
          <Navbar />

          <FadeIn>
            <div className="page-block">
              <div>
                <p>
                  <Link to="/projects">&larr; back</Link>
                </p>
                <div style={{ height: "20px" }} />
                <h2>Suicune</h2>
                <p className="project-description">
                  <em>built in 2017</em>
                  &nbsp;//&nbsp;
                  <ExternalLink href={CalculationsPdf}>
                    Calculations
                  </ExternalLink>
                </p>

                <p>
                  i built a 3 foot model of one of my favorite pokémon, suicune,
                  out of paper mache!
                </p>

                <div style={{ height: "30px" }} />

                <iframe
                  style={{
                    width: "100%",
                    height: "100%",
                    minHeight: "360px",
                    border: "1px solid #ccc",
                  }}
                  src="https://www.youtube.com/embed/lLHParPfzO8"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                />

                <div style={{ height: "30px" }} />

                <Carousel>
                  <div>
                    <img src={Suicune1} alt="Suicune" />
                    <p className="legend">
                      Step 1: Making the skeleton using chicken wire and foam
                      board.
                    </p>
                  </div>
                  <div>
                    <img src={Suicune2} alt="Suicune" />
                    <p className="legend">
                      Step 2: Paper macheing ~8 layers of newspaper to skeleton
                      for firmness.
                    </p>
                  </div>
                  <div>
                    <img src={Suicune3} alt="Suicune" />
                    <p className="legend">
                      Step 3: Paper macheing headpiece with tissue paper and
                      installing fairy lights.
                    </p>
                  </div>
                  <div>
                    <img src={Suicune4} alt="Suicune" />
                    <p className="legend">
                      Step 4: Finalizing last paper mache layer using copy
                      paper.
                    </p>
                  </div>
                  <div>
                    <img src={Suicune5} alt="Suicune" />
                    <p className="legend">Step 5: Basic coat paint.</p>
                  </div>
                  <div>
                    <img src={Suicune6} alt="Suicune" />
                    <p className="legend">
                      Step 6: Painting in details and installing backpiece using
                      spray-painted cotton balls.
                    </p>
                  </div>
                  <div>
                    <img src={Suicune7} alt="Suicune" />
                    <p className="legend">Final product! :)</p>
                  </div>
                  <div>
                    <img src={Suicune8} alt="Suicune" />
                    <p className="legend">Final product! :)</p>
                  </div>
                  <div>
                    <img src={Suicune9} alt="Suicune" />
                    <p className="legend">Final product! :)</p>
                  </div>
                </Carousel>
              </div>
            </div>
          </FadeIn>
        </div>
      </body>
    </PageLayout>
  )
}

export default SuicunePage
